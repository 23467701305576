import {
  format, add, startOfMonth, startOfYear,
} from 'date-fns'

export default {
  methods: {
    formatMeliState(value) {
      if (value) {
        value = value.toString().replace('BR-', '') // eslint-disable-line
      }
      return value
    },
    formatMeliItemId(value) {
      if (value) {
        value = value.toString().replace('MLB', '#') // eslint-disable-line
      }
      return value
    },
    getPaymentStatus(paymentStatus) {
      return this.$i18n.t(`components.cards.meli.mp.collections.statuses.${paymentStatus}`)
    },
    getPaymentType(paymentType, paymentMethod) {
      const paymentMethods = {
        visa: 'Visa',
        master: 'Mastercard',
        elo: 'Elo',
        amex: 'Amex',
        hipercard: 'Hipercard',
        aura: 'Aura',
        diners: 'Diners',
        melicard: 'MeliCard',
        debelo: 'Elo',
        debvisa: 'Visa',
        debmaster: 'Mastercard',
        consumer_credits: 'Mercado Crédito',
        giftcard: 'Cartão Presente',
        paypal: 'Paypal',
        pec: 'Lotérica',
        pix: 'Pix',
        pix_am: 'Pix',
      }

      let pType = this.$i18n.t(`components.cards.meli.mp.collections.types.${paymentType}`)

      const pMethod = paymentMethods[paymentMethod]
      if (pMethod) {
        pType += ' ' + this.$i18n.t(`components.cards.meli.mp.collections.methods.${paymentMethod}`) // eslint-disable-line
      }

      return pType
    },
    getPaymentLogo(paymentType, paymentMethod) {
      const paymentTypes = {
        credit_card_visa: 'd589be70-eb86-11e9-b9a8-097ac027487d-xl.svg',
        credit_card_master: 'af299100-eb87-11e9-826d-3b99bc547094-xl.svg',
        credit_card_elo: 'be40d2e0-ec57-11e9-b9a8-097ac027487d-xl.svg',
        credit_card_amex: 'b08cf800-4c1a-11e9-9888-a566cbf302df-xl.svg',
        credit_card_hipercard: 'fcdc39d0-57ad-11e8-8359-5d73691de80c-xl.svg',
        debit_card_debelo: 'be40d2e0-ec57-11e9-b9a8-097ac027487d-xl.svg',
        account_money_account_money: 'b4534650-571b-11e8-95d8-631c1a9a92a9-xl.svg',
        ticket_bolbradesco: '00174300-571e-11e8-8364-bff51f08d440-xl.svg',
        ticket_pec: 'faa7e7b0-cd6c-11e8-9918-9ff3784f1905-xl.svg',
        bank_transfer_pix: '200454b0-13c2-11eb-a75e-3f6b552aa946-xl.svg',
        digital_currency_consumer_credits: '51b446b0-571c-11e8-9a2d-4b2bd7b1bf77-xl.svg',
        digital_wallet_paypal: 'ae8c5640-9944-11ea-9fa5-f9984f8e6a55-xl.svg',
      }

      const pLogo = paymentTypes[`${paymentType}_${paymentMethod}`]

      if (pLogo) {
        return `https://http2.mlstatic.com/storage/logos-api-admin/${pLogo}`
      }
      return ''
    },
    getPaymentColor(paymentStatus) {
      const paymentStatuses = {
        pending: 'info',
        approved: 'success',
        in_process: 'warning',
        in_mediation: 'warning',
        authorized: 'warning',
        rejected: 'danger',
        cancelled: 'warning',
        refunded: 'danger',
        charged_back: 'dark',
      }

      return paymentStatuses[paymentStatus]
    },
    getPaymentStatusIcon(paymentStatus) {
      const paymentStatuses = {
        pending: ['fas', 'clock'],
        approved: ['fas', 'check'],
        in_process: ['fas', 'clock'],
        in_mediation: ['fas', 'exclamation-triangle'],
        authorized: ['fas', 'check-circle'],
        rejected: ['fas', 'ban'],
        cancelled: ['fas', 'times'],
        refunded: ['fas', 'times-circle'],
        charged_back: ['fas', 'exclamation-circle'],
      }

      return paymentStatuses[paymentStatus]
    },
    getRangeCriteria(criteriaType, periodType, adjustPeriod, dateFormat) {
      const criteria = {
        option: 'range',
      }
      switch (criteriaType) {
        case 'release':
          criteria.value_id = 'money_release_date'
          criteria.text = 'Liberados '
          break
        case 'approved':
          criteria.value_id = 'date_approved'
          criteria.text = 'Aprovados '
          break
        case 'created':
          criteria.value_id = 'date_created'
          criteria.text = 'Criados '
          break
        default:
          criteria.value_id = 'date_created'
          criteria.text = 'Criados '
          break
      }

      if (adjustPeriod >= -1 && adjustPeriod <= 1) {
        switch (periodType) {
          case 'day':
            criteria.text += (adjustPeriod === 0) ? 'hoje' : 'ontem'
            break
          case 'week':
            criteria.text += 'semana ' + ((adjustPeriod === 0) ? 'atual' : 'anterior') // eslint-disable-line
            break
          case 'month':
            criteria.text += 'mês ' + ((adjustPeriod === 0) ? 'atual' : 'anterior') // eslint-disable-line
            break
          case 'year':
            criteria.text += 'ano ' + ((adjustPeriod === 0) ? 'atual' : 'anterior') // eslint-disable-line
            break
          default:
            criteria.text += 'ano ' + ((adjustPeriod === 0) ? 'atual' : 'anterior') // eslint-disable-line
            break
        }
      } else {
        switch (periodType) {
          case 'day':
            criteria.text += 'últimos ' + adjustPeriod.toString().replace(/-/g, '') + ' dias' // eslint-disable-line
            break
          case 'week':
            criteria.text += 'últimos ' + adjustPeriod.toString().replace(/-/g, '') + ' semanas' // eslint-disable-line
            break
          case 'month':
            criteria.text += 'últimos ' + adjustPeriod.toString().replace(/-/g, '') + ' meses' // eslint-disable-line
            break
          case 'year':
            criteria.text += 'últimos ' + adjustPeriod.toString().replace(/-/g, '') + ' anos' // eslint-disable-line
            break
          default:
            criteria.text += 'últimos ' + adjustPeriod.toString().replace(/-/g, '') + ' anos' // eslint-disable-line
            break
        }
      }

      let startPeriod = new Date()
      let endPeriod = 'NOW'

      switch (periodType) {
        case 'week':
          criteria.text += 'semana ' + ((adjustPeriod === 0) ? 'atual' : 'anterior') // eslint-disable-line
          break
        case 'month':
          startPeriod = startOfMonth(add(startPeriod, { months: adjustPeriod }))
          break
        case 'year':
          startPeriod = startOfYear(add(startPeriod, { years: adjustPeriod }))
          break
        default:
          break
      }

      if (adjustPeriod < 0) {
        endPeriod = format(startPeriod, dateFormat)
        startPeriod = format(add(startPeriod, adjustPeriod, periodType), dateFormat)
      } else {
        if (adjustPeriod > 0 && criteriaType === 'release') { // eslint-disable-line
          criteria.text = 'A Liberar'
          if (adjustPeriod === 1) {
            criteria.text = 'A Liberar hoje'
          }
          if (adjustPeriod === 2) {
            criteria.text = 'A Liberar até amanhã'
          }
          if (adjustPeriod > 2) {
            criteria.text = 'A Liberar futuramente'
          }
          endPeriod = format(startPeriod.add(adjustPeriod, periodType), dateFormat)
          startPeriod = 'NOW'
        } else {
          startPeriod = format(startPeriod, dateFormat)
        }
      }

      criteria.begin_date = startPeriod
      criteria.end_date = endPeriod

      console.log(startPeriod)
      console.log(endPeriod)

      return criteria
    },
  },
}
