import meliClient from './core/client'

export default {
  getQuestions(request) {
    const body = {
      method: 'GET',
      url: '/questions/search',
      parameters: {
        access_token: request.accessToken,
        id: request.id ? request.id : null,
        status: request.status ? request.status : null,
        sort_fields: request.sortBy,
        sort_types: request.sortDirection.toUpperCase(),
        limit: request.limit,
        offset: request.offset,
      },
    }

    if (request.itemId) {
      body.parameters.item = request.itemId
    }
    if (request.sellerId) {
      body.parameters.seller_id = request.sellerId
    }
    if (request.fromId) {
      body.parameters.from = request.fromId
    }

    return meliClient.callMeliProcess(body)
  },
  answerQuestion(accessToken, questionId, answerText) {
    const body = {
      method: 'POST',
      url: 'answers',
      body: {
        question_id: questionId,
        text: answerText,
      },
      parameters: {
        access_token: accessToken,
      },
    }

    return meliClient.callMeliProcess(body)
  },
  deleteQuestion(accessToken, questionId) {
    const body = {
      method: 'DELETE',
      url: '/questions/' + questionId, // eslint-disable-line
      parameters: {
        access_token: accessToken,
      },
    }

    return meliClient.callMeliProcess(body)
  },
}
