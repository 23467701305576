<template>
  <b-card no-body class="p-sm-0 pl-0 pr-0">
    <!-- form -->
    <validation-observer ref="answerTemplateForm" #default="{invalid}">
      <b-form>
        <b-row>
          <!-- answer -->
          <b-col lg="6">
            <b-form-group label-for="answer">
              <span slot="description" class="float-right">{{ form.content.length }}/500</span>
              <span slot="description">{{ $t('components.cards.meli.questions.settings.answer_templates_answer_description') }}</span>
              <span slot="label"><b>{{ $t('components.cards.meli.questions.settings.answer_templates_answer') }}</b></span>
              <validation-provider #default="{ errors }" :name="$t('components.cards.meli.questions.settings.answer_templates_answer')"
                rules="required|min:6">
                <b-form-textarea id="answer" v-model="form.content" :placeholder="$t('components.cards.meli.questions.settings.answer_templates_answer_placeholder')" rows="3" max-rows="6"
                  maxLength="500" class="form-control" :state="errors.length > 0 ? false:null">
                </b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ answer -->
        </b-row>
        <b-row>
          <!-- tag -->
          <b-col lg="6">
            <b-form-group label-for="tag">
              <span slot="description" class="float-right">{{ form.tag.length }}/20</span>
              <span slot="description">{{ $t('components.cards.meli.questions.settings.answer_templates_tag_description') }}</span>
              <span slot="label"><b>{{ $t('components.cards.meli.questions.settings.answer_templates_tag') }}</b></span>
              <validation-provider #default="{ errors }" :name="$t('components.cards.meli.questions.settings.answer_templates_tag')"
                rules="required|min:3">
                <b-form-input id="tag" v-model="form.tag"
                  :name="$t('components.cards.meli.questions.settings.answer_templates_tag')" :placeholder="$t('components.cards.meli.questions.settings.answer_templates_tag_placeholder')" maxlength="20" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ tag -->
        </b-row>
        <b-row>
          <!-- buttons -->
          <b-col cols="2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1" :disabled="invalid || formSubmitted" @click="preSubmit">
              <font-awesome-icon :icon="['fas', 'check']" v-if="!formSubmitted" class="mr-50" />
              <b-spinner v-if="formSubmitted" class="mr-50" :type="spinnerType" style="width: 16px; height: 16px;" />
              {{ $t('common.save') }}
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" class="mt-1" @click="resetForm">
              <font-awesome-icon :icon="['fas', 'times']" class="mr-50" />
              {{ $t('common.reset') }}
            </b-button>
          </b-col>
          <b-col cols="4">
            <b-alert v-if="form.id" show :variant="colorVariant('', 'warning')" class="mt-1">
              <div class="alert-body">
                <feather-icon class="mr-25" size="18" icon="AlertCircleIcon" />
                <span class="ml-25" v-html="$t('components.cards.meli.questions.settings.answer_templates_edit')"></span>
              </div>
            </b-alert>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>

    <b-table responsive hover :items="answerTemplates"
      :fields="answerTemplatesFields.fields" class="mb-0 p-sm-1">

      <template #table-colgroup="scope">
        <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.size }">
      </template>

      <template #head(content)>
        {{ $t('components.cards.meli.questions.settings.answer_templates_answer') }}
      </template>
      <template #head(tag)>
        {{ $t('components.cards.meli.questions.settings.answer_templates_tag') }}
      </template>

      <template #cell(tag)="data">
        <b-badge variant="primary" pill>#{{ data.value }}</b-badge>
      </template>

      <template #cell(actions)="data">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" size="sm" class="mr-50"
          @click="prepareEdit(data)">
          <font-awesome-icon :icon="['far', 'edit']" />
        </b-button>
        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="danger" size="sm"
          @click="confirmDelete(data)">
          <font-awesome-icon :icon="['far', 'trash-alt']" />
        </b-button>
      </template>

      <template #cell()="data">
        {{ data.value }}
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormGroup, BFormInput, BFormTextarea, BRow, BCol, BCard, BSpinner, BTable, BBadge, BAlert,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { $themeConfig } from '@themeConfig'
import Crypt from '@core-md/utils/crypt'
import handleAxiosException from '@core-md/mixins/errors'
import variants from '@core-md/mixins/variants'
import answerTemplatesFields from './_answerTemplatesFields'
import questionsService from '@/services/meli/questions/client'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BCard,
    BSpinner,
    BTable,
    BBadge,
    BAlert,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    account: {
      type: Object,
      default: null,
    },
    inherit: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  directives: {
    Ripple,
  },
  mixins: [
    handleAxiosException,
    variants,
  ],
  data() {
    return {
      selectedAccount: null,
      answerTemplatesFields,
      answerTemplates: [],
      form: {
        id: '',
        content: '',
        tag: '',
      },
      formSubmitted: false,
      // validation
      required,
      unWatchSelectedAccount: null,
    }
  },
  computed: {
    isPayerUser() {
      return new Date(this.selectedAccount.expire_date) >= new Date()
    },
  },
  setup() {
    const { spinnerType } = $themeConfig.layout

    return {
      spinnerType,
    }
  },
  mounted() {
    if (!this.inherit || this.account) {
      this.selectedAccount = this.account
    } else {
      this.selectedAccount = this.$store.state.authorizations.selectedAccount
      this.unWatchSelectedAccount = this.$store.watch(() => this.$store.state.authorizations.selectedAccount, account => {
        if (account) {
          this.selectedAccount = account
          this.getAnswerTemplates()
        }
      })
    }
    this.$store.dispatch('meli/initialize')
    this.getAnswerTemplates()
  },
  beforeDestroy() {
    if (this.unWatchSelectedAccount) {
      this.unWatchSelectedAccount()
      this.unWatchSelectedAccount = null
    }
  },
  methods: {
    getAnswerTemplates() {
      this.resetForm()
      const request = {
        seller_id: this.selectedAccount.seller_id,
      }
      questionsService.getAnswerTemplates(request).then(response => {
        if (response.status === 200) {
          const result = Crypt.decrypt(response.data.result)
          if (result) {
            this.answerTemplates = result
          }
        }
      }).catch(data => {
        console.log(data)
      })
    },
    prepareEdit(answer) {
      this.form.id = answer.item.id
      this.form.content = answer.item.content
      this.form.tag = answer.item.tag
    },
    preSubmit() {
      this.submitForm()
    },
    submitForm() {
      this.form.tag = this.form.tag.replace(/\s+/g, '').replace(/#/g, '')
      this.$refs.answerTemplateForm.validate().then(success => {
        if (success) {
          this.formSubmitted = true
          const request = this.form
          questionsService.saveAnswerTemplate(request).then(response => {
            if (response.status === 200) {
              const result = Crypt.decrypt(response.data.result)
              if (result.status
                && result.status === '0') {
                this.$root.showMessageSuccess(result.statusMessageDetail || result.statusMessage, this.$i18n.t('common.messages.success'))
                this.getAnswerTemplates()
              } else {
                this.$root.showMessageError(result.statusMessageDetail || result.statusMessage, this.$i18n.t('components.cards.meli.questions.settings.messages.general_error'))
              }
              this.formSubmitted = false
            }
          }).catch(error => {
            this.formSubmitted = false
            this.handleAxiosException(error, 'components.cards.meli.questions.settings.messages.general_error')
          })
        }
      })
    },
    resetForm() {
      this.form = {
        id: '',
        seller_id: this.selectedAccount.seller_id,
        content: '',
        tag: '',
      }
      this.$refs.answerTemplateForm.reset()
    },
    confirmDelete(data) {
      this.$bvModal
        .msgBoxConfirm(data.item.content, {
          title: this.$i18n.t('components.cards.meli.questions.settings.answer_templates_delete_title'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$i18n.t('common.delete'),
          cancelTitle: this.$i18n.t('common.cancel'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.formSubmitted = true
            const request = { id: data.item.id }
            questionsService.deleteAnswerTemplate(request).then(response => {
              if (response.status === 200) {
                const result = Crypt.decrypt(response.data.result)
                if (result.status
                  && result.status === '0') {
                  this.$root.showMessageSuccess(result.statusMessageDetail || result.statusMessage, this.$i18n.t('common.messages.success'))
                  this.getAnswerTemplates()
                } else {
                  this.$root.showMessageError(result.statusMessageDetail || result.statusMessage, this.$i18n.t('components.cards.meli.questions.settings.messages.general_error'))
                }
                this.formSubmitted = false
              }
            }).catch(error => {
              this.formSubmitted = false
              this.handleAxiosException(error, 'components.cards.meli.questions.settings.messages.general_error')
            })
          }
        })
    },
  },
}
</script>
