export default {
  // Endpoints
  answerTemplatesResultsEndpoint: '/answerTemplates/results.json',
  answerTemplatesSaveEndpoint: '/answerTemplates/save.json',
  answerTemplatesDeleteEndpoint: '/answerTemplates/delete.json',

  answerConfigurationsResultsEndpoint: '/answerConfigurations/results.json',
  answerConfigurationsSaveEndpoint: '/answerConfigurations/save.json',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'auth_token',
  storageRefreshTokenKeyName: 'refreshToken',
  storageUserDataKeyName: 'userData',
}
