export default {
  fields: [
    {
      key: 'question',
      label: '',
      sortable: false,
      tdClass: 'pl-0 pl-sm-1',
      thClass: 'pl-0 pl-sm-1 text-left',
    },
  ],
}
