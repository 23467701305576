!<template>
  <b-row class="m-0">
    <b-col cols="9" class="m-0">
      <span>
        <span v-if="from.nickname" @click="question.visible = !question.visible">
          <small class="text-primary"><font-awesome-icon :icon="['far', 'user']" fixed-width /> {{ from.nickname }} <span v-if="from.points">({{ from.points }})</span></small>
          <span v-if="getLocation(from)"><small class="text-secondary"> - {{ getLocation(from) }}</small></span>
          <font-awesome-icon :id="'popUser' + question.id" :icon="['far', 'address-book']" class="text-primary" fixed-width />
          <b-popover :target="'popUser' + question.id" triggers="hover" placement="right">
            <template v-slot:title><strong>{{ $t('meli.user') }}: {{ from.nickname }}</strong></template>
            <b-row class="m-0">
              <b-col cols="12" class="m-0">
                {{ $t('meli.identification.id') }}: {{ from.id }}
                <b-link :id="'lnkCopyUserId' + question.id" v-clipboard:copy="from.id" v-clipboard:success="clipboardSuccessHandler"
                  data-copy-field="ID">
                  <font-awesome-icon :icon="['far', 'copy']" fixed-width />
                </b-link>
                <b-tooltip :target="'lnkCopyUserId' + question.id" triggers="hover" placement="bottom">
                  {{ $t('common.copy', {'field': $t('meli.identification.id')}) }}
                </b-tooltip>
                <br>
                {{ $t('meli.identification.nickname') }}: {{ from.nickname }}
                <b-link :id="'lnkCopyUserNickname' + question.id" v-clipboard:copy="from.nickname" v-clipboard:success="clipboardSuccessHandler"
                  data-copy-field="Apelido">
                  <font-awesome-icon :icon="['far', 'copy']" fixed-width />
                </b-link>
                <b-tooltip :target="'lnkCopyUserNickname' + question.id" triggers="hover" placement="bottom">
                  {{ $t('common.copy', {'field': $t('meli.identification.nickname')}) }}
                </b-tooltip>
                <br>
                <span v-if="from.points">{{ $t('meli.identification.points') }}: {{ from.points }}<br></span>
                <span v-if="from.registration_date">{{ $t('meli.identification.registration_date') }}: {{ getRegistrationDate(from, true) }}<br></span>
              </b-col>
            </b-row>
            <b-row class="m-0">
              <b-col cols="12" class="m-0">
                <b-button :id="'btnProfile' + question.id" size="sm" variant="outline-primary" class="btn-rad"
                  v-bind:href="from.permalink" target="_blank">
                  <font-awesome-icon :icon="['fas', 'external-link-alt']" class="text-primary" fixed-width />
                  <span class="d-none d-sm-inline"> {{ $t('meli.profile') }}</span>
                </b-button>
                <b-tooltip :target="'btnProfile' + question.id" triggers="hover" placement="bottom">
                  {{ $t('meli.view_profile') }}
                </b-tooltip>
              </b-col>
            </b-row>
          </b-popover>
          <span v-if="from.registration_date">
            <small>{{ $t('meli.identification.user_since') }} {{ getRegistrationDate(from) }}
            </small>
          </span>
        </span>
        <span v-else>
          {{ $t('components.cards.meli.questions.name_unavailable') }}
          <font-awesome-icon :id="'iNameUnavailable' + question.id" :icon="['fas', 'info-circle']" class="text-primary"
            fixed-width /><br />
        </span><br/>
        <span v-if="from.answered_questions > 0">
          {{ $t('components.cards.meli.questions.answered_questions', {'questions': from.answered_questions}) }}
        </span>
        <b-row>
          <b-col cols="9">
            <span class="text-danger" @click="question.visible = !question.visible">
              <font-awesome-icon :icon="['fas', 'comment']" fixed-width />
              <strong>{{ question.text }}</strong> <font-awesome-icon v-if="!question.visible" :icon="['fas', 'angle-down']" fixed-width /><font-awesome-icon v-if="question.visible" :icon="['fas', 'angle-up']" fixed-width />
            </span>
          </b-col>
          <b-col cols="3">
            <small class="text-danger float-right"><font-awesome-icon :icon="['fas', 'comment']" /> {{ createdDate }}</small>
          </b-col>
        </b-row>
        <b-row v-if="question.answer && question.answer.text">
          <b-col cols="9">
            <span class="text-success">
              <font-awesome-icon :icon="['fas', 'comments']" fixed-width />
              <strong>{{ question.answer.text }}</strong>
            </span>
          </b-col>
          <b-col cols="3">
            <small class="text-success float-right"><font-awesome-icon :icon="['fas', 'comments']" />{{ answeredDate }}</small>
          </b-col>
        </b-row>
        <b-row v-if="question.deleted">
          <b-col cols="9">
            <span class="text-danger">
              <font-awesome-icon :icon="['far', 'trash-alt']" fixed-width />
              <strong>{{ $t('components.cards.meli.questions.deleted_question') }}</strong>
            </span>
          </b-col>
        </b-row>
        <b-row v-if="question.visible && !question.answer && !question.deleted" class="m-0">
          <validation-observer ref="answerForm" #default="{invalid}">
            <b-col sm="12" class="m-0 p-0">
              <b-row class="m-0 p-0">
                <b-col sm="12" class="m-0 p-0">
                  <b-form-group class="col-sm-12 m-0 p-0" :label-for="'txtAnswer' + question.id">
                    <span slot="description" class="float-right">{{ question.answerText.length }}/2000</span>
                    <span slot="description">
                      <span>{{ $t('components.cards.meli.questions.no_contact') }} </span>
                      <span class="text-primary">{{ $t('components.cards.meli.questions.more_info') }}</span><br>
                      <span v-if="!isPayerUser" class="text-danger">
                        <br><strong><font-awesome-icon :icon="['fas', 'exclamation-triangle']" fixed-width /> {{ $t('common.info.payers_only_funtionality') }}</strong>
                      </span>
                    </span>
                    <span v-if="isPayerUser && !question.error" slot="description">
                      {{ $t('components.cards.meli.questions.answer_info') }}
                      <span v-if="answerConfiguration && answerConfiguration.automatic_greeting && answerConfiguration.greeting">{{ $t('components.cards.meli.questions.automatic_geeting_on') }}</span>
                      <span v-if="answerConfiguration && answerConfiguration.automatic_signature && answerConfiguration.signature">{{ $t('components.cards.meli.questions.automatic_signature_on') }}</span>
                    </span>
                    <span v-if="question.error" slot="description" class="text-danger">{{ question.error }}</span>
                    <validation-provider #default="{ errors }"
                      :name="$t('components.cards.meli.questions.settings.answer_templates_answer')" rules="required">
                      <b-form-textarea :ref="'txtAnswer' + question.id" :id="'txtAnswer' + question.id" v-model="question.answerText"
                        @input="enterQuestion($event)" :placeholder="$t('components.cards.meli.questions.answer_placeholder')" rows="3" max-rows="6"
                        class="form-control" :state="errors.length > 0 ? false:null" maxlength="2000" style="width:800px">
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-textarea>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="isPayerUser" class="m-0 p-0">
                <!-- buttons -->
                <b-col cols="12" class="m-0 p-0">
                  <b-button size="sm" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mt-1 mr-1"
                    @click="answerQuestion" :disabled="invalid || formSubmitted">
                    <font-awesome-icon :icon="['fas', 'paper-plane']" class="mr-50" />
                    {{ $t('components.cards.meli.questions.answer') }}
                  </b-button>
                  <b-button size="sm" v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" class="mt-1 mr-1"
                    @click="clearQuestion">
                    <font-awesome-icon :icon="['fas', 'times']" class="mr-50" />
                    {{ $t('common.reset') }}
                  </b-button>
                  <b-button size="sm" v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="danger" class="mt-1 float-right"
                    v-b-modal="'mdConfirmDelete_' + question.id">
                    <font-awesome-icon :icon="['far', 'trash-alt']" class="mr-50" />
                    {{ $t('common.delete') }}
                  </b-button>
                </b-col>
                <!--/ buttons -->
              </b-row>
            </b-col>
          </validation-observer>
        </b-row>
      </span>
      <b-modal :id="'mdConfirmDelete_' + question.id" centered
        header-bg-variant="danger" no-close-on-backdrop ok-variant="danger" button-size="sm">
        <template #modal-header="{ close }">
          <h5 class="text-light">{{ $t('components.cards.meli.questions.confirm_delete') }}</h5>
          <button type="button" aria-label="Close" class="close" @click="close">×</button>
        </template>
        <p class="my-4">
          <strong>{{ question.text }}</strong>
        </p>
        <p>
          <b-form-checkbox inline button-variant="danger" v-model="question.blockQuestion">
            {{ $t('components.cards.meli.questions.block_question') }}
          </b-form-checkbox>
          <b-form-checkbox inline v-model="question.blockBuy">
            {{ $t('components.cards.meli.questions.block_buy') }}
          </b-form-checkbox>
        </p>
        <template #modal-footer="{ close }">
          <b-button size="sm" v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="danger" class="mr-50" @click="deleteQuestion(question)">
            <font-awesome-icon :icon="['far', 'trash-alt']" class="mr-50" /> {{ $t('common.delete') }}
          </b-button>
          <b-button size="sm" v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-danger" @click="close">
            <font-awesome-icon :icon="['fas', 'times']" class="mr-50" /> {{ $t('common.cancel') }}
          </b-button>
        </template>
      </b-modal>
    </b-col>
    <b-col cols="3" class="m-0">
      <answer-templates v-if="!question.answer && question.visible && !question.deleted"
        :question="question" :answerTemplates="answerTemplates" :answerConfiguration="answerConfiguration"
        :isPayerUser="isPayerUser" :showDetail="options.showDetail" @click="addAnswer" />
      <b-button size="sm" v-if="!question.visible && !question.answer && !question.deleted" v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="danger" class="mt-1 float-right"
        v-b-modal="'mdConfirmDelete_' + question.id">
        <font-awesome-icon :icon="['far', 'trash-alt']" class="mr-50" />
        {{ $t('common.delete') }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BButton, BLink, VBTooltip, BTooltip, BPopover, BFormGroup, BFormTextarea, BFormCheckbox, BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import stringHelper from '@core-md/mixins/stringHelper'
import meliHelper from '@core-md/mixins/meliHelper'
import { format } from 'date-fns'
import meliQuestions from '@/services/meli/questions'
import meliUsers from '@/services/meli/users'
import AnswerTemplates from './AnswerTemplates.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BButton,
    BTooltip,
    BPopover,
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
    BModal,
    AnswerTemplates,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    question: {
      type: Object,
      default: null,
    },
    answerConfiguration: {
      type: Object,
      default: null,
    },
    answerTemplates: {
      type: Array,
      default: null,
    },
    isPayerUser: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: null,
    },
    selectedAccount: {
      type: Object,
      default: null,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [
    stringHelper,
    meliHelper,
  ],
  data() {
    return {
      showTicket: false,
      formSubmitted: false,
    }
  },
  computed: {
    from() {
      return this.question.from
    },
    createdDate() {
      return format(new Date(this.question.date_created), 'dd/MM/yyyy HH:mm')
    },
    answeredDate() {
      return format(new Date(this.question.answer.date_created), 'dd/MM/yyyy HH:mm')
    },
    stacked() {
      if (this.options && this.options.tableStacked) {
        return this.options.tableStacked
      }
      return false
    },
    autoGreeting() {
      return this.getGreetingByHour()
    },
    mustShow() {
      return this.options.showDetail
    },
  },
  watch: {
    mustShow() {
      this.question.visible = this.mustShow
    },
  },
  methods: {
    getLocation(user) {
      let location = ''
      if (user.address && user.address.city) {
        location = user.address.city
        if (user.address.state) {
          location += '/' + user.address.state.split('-')[1] // eslint-disable-line
        }
      }
      return location
    },
    getRegistrationDate(user, full = false) {
      if (user.registration_date) {
        return format(new Date(user.registration_date), full ? 'dd/MM/yyyy HH:mm' : 'MM/yyyy')
      }
      return ''
    },
    enterQuestion(inputValue) {
      let answer = inputValue
      this.answerTemplates.forEach(value => {
        answer = answer.replace('#' + value.tag, value.content) // eslint-disable-line
      })
      answer = answer.replace('#autosaudacao', this.autoGreeting + this.question.from.nickname) // eslint-disable-line
      if (this.answerConfiguration.greeting) {
        answer = answer.replace('#saudacao', this.answerConfiguration.greeting + this.question.from.nickname) // eslint-disable-line
      }
      if (this.answerConfiguration.signature) {
        answer = answer.replace('#assinatura', this.answerConfiguration.signature)
      }
      if (this.question.options) {
        this.question.options.forEach((value, key) => {
          answer = answer.replace('#autofrete' + (key + 1), 'Frete ' + value.name + ((value.cost > 0) ? ' ' + this.formatMoney(value.cost) : ' Grátis') + (value.estimated_delivery ? ' com previsão de entrega em ' + value.estimated_delivery + ' dias úteis após a postagem.' : '.')) // eslint-disable-line
        })
      }
      this.question.answerText = answer
    },
    clearQuestion() {
      this.question.answerText = ''
      this.question.error = ''
    },
    addAnswer(content) {
      this.question.answerText += content + ' ' // eslint-disable-line
    },
    answerQuestion() {
      this.$refs.answerForm.validate().then(success => {
        if (success) {
          let answerText = this.question.answerText // eslint-disable-line
          if (this.answerConfiguration.automatic_greeting && this.answerConfiguration.greeting) {
            answerText = this.answerConfiguration.greeting + this.question.from.nickname + '. ' + answerText // eslint-disable-line
          }
          if (this.answerConfiguration.automatic_signature && this.answerConfiguration.signature) {
            answerText += ' ' + this.answerConfiguration.signature // eslint-disable-line
          }
          this.formSubmitted = true
          meliQuestions.answerQuestion(this.selectedAccount.access_token, this.question.id, answerText).then(response => {
            if (response.httpCode === 200 || response.httpCode === 201) {
              this.question.answer = response.body.answer
              this.$forceUpdate()
              this.$root.showMessageSuccess(this.$i18n.t('components.cards.meli.questions.messages.success'), this.$i18n.t('components.cards.meli.questions.page_title'))

              this.$emit('questionAnswered', this.question)
            } else {
              this.$root.showMessageError(this.$i18n.t('components.cards.meli.questions.messages.error'), this.$i18n.t('components.cards.meli.questions.page_title'))
            }
            this.formSubmitted = false
          }).catch(error => {
            this.formSubmitted = false
            this.handleAxiosException(error, 'components.cards.meli.questions.page_title')
          })
        }
      })
    },
    deleteQuestion() {
      this.formSubmitted = true
      meliQuestions.deleteQuestion(this.selectedAccount.access_token, this.question.id).then(response => {
        if (response.httpCode === 200) {
          this.question.deleted = true // eslint-disable-line
          this.$forceUpdate()
          this.$root.showMessageSuccess(this.$i18n.t('components.cards.meli.questions.messages.delete_success'), this.$i18n.t('common.messages.success'))

          if (this.question.blockBuy) {
            meliUsers.addToOrderBlocklist(this.selectedAccount.access_token, this.selectedAccount.seller_id, this.question.from.id).then(responseBlockBuy => {
              if (responseBlockBuy.httpCode === 200 || responseBlockBuy.httpCode === 201) {
                this.$root.showMessageSuccess(this.$i18n.t('components.cards.meli.settings.blocklists.messages.success_block', { user: this.question.from.nickname }), this.$i18n.t('components.cards.meli.settings.blocklists.messages.title_block'), 'LockIcon') // eslint-disable-line prefer-template
              } else {
                this.$root.showMessageError(this.$i18n.t('components.cards.meli.settings.blocklists.messages.error_block', { user: this.question.from.nickname }), this.$i18n.t('components.cards.meli.settings.blocklists.messages.title_block'), 'LockIcon')
              }
            })
          }
          if (this.question.blockQuestion) {
            meliUsers.addToQuestionBlocklist(this.selectedAccount.access_token, this.selectedAccount.seller_id, this.question.from.id).then(responseBlockQuestion => {
              if (responseBlockQuestion.httpCode === 200 || responseBlockQuestion.httpCode === 201) {
                this.$root.showMessageSuccess(this.$i18n.t('components.cards.meli.settings.blocklists.messages.success_block', { user: this.question.from.nickname }), this.$i18n.t('components.cards.meli.settings.blocklists.messages.title_block'), 'LockIcon') // eslint-disable-line prefer-template
              } else {
                this.$root.showMessageError(this.$i18n.t('components.cards.meli.settings.blocklists.messages.error_block', { user: this.question.from.nickname }), this.$i18n.t('components.cards.meli.settings.blocklists.messages.title_block'), 'LockIcon')
              }
            })
          }

          this.formSubmitted = false
          this.$root.$emit('bv::hide::modal', 'mdConfirmDelete_' + this.question.id) // eslint-disable-line
          this.$emit('questionDeleted', this.question)
        } else {
          this.$root.showMessageError(this.$i18n.t('components.cards.meli.questions.messages.delete_error'), this.$i18n.t('components.cards.meli.questions.page_title'))
          this.formSubmitted = false
        }
      }).catch(error => {
        this.formSubmitted = false
        this.handleAxiosException(error, 'components.cards.meli.questions.page_title')
      })
    },
  },
}
</script>
