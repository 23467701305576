<template>
  <span>
    <span class="text-primary"><strong><font-awesome-icon :icon="['fas', 'bolt']" class="mr-50" /> {{ $t('components.cards.meli.questions.quick_answers') }}</strong></span>
    <b-row class="m-0 p-0">
      <b-col class="m-0 p-0">
        <b-badge :id="'bAutoGreeting' + question.id" variant="warning" pill
          @click="addQuestion(autoGreeting + question.from.nickname + '. ')">
          #autosaudacao</b-badge>&nbsp;
        <b-badge v-if="answerConfiguration.greeting && !answerConfiguration.automatic_greeting"
          :id="'bGreeting' + question.id" variant="warning" pill
          @click="addQuestion(answerConfiguration.greeting + question.from.nickname + '. ')">
          #saudacao</b-badge>&nbsp;
        <b-badge v-for="(item, index) in question.options" v-bind:key="item.id" variant="warning" pill
          @click="addQuestion('Frete ' + item.name + ((item.cost>0)?' ' + formatMoney(item.cost):' Grátis') + (item.estimated_delivery?' com previsão de entrega em ' + item.estimated_delivery + ' dias úteis após a postagem.':'.'))">
          #autofrete{{index + 1}}</b-badge>&nbsp;
      </b-col>
    </b-row>
    <b-row class="m-0 p-0">
      <b-col class="m-0 p-0">
        <span v-for="(item, index) in answerTemplates" :key="item.id">
          <span v-if="item.tag">
            <b-badge :id="'b' + item.id + index" variant="primary" pill @click="addQuestion(item.content)">
              #{{ item.tag }}</b-badge>&nbsp;
          </span>
        </span>
      </b-col>
    </b-row>
    <b-row class="m-0 p-0">
      <b-col class="m-0 p-0">
        <b-badge v-if="answerConfiguration.signature && !answerConfiguration.automatic_signature" :id="'bSignature' + question.id"
          variant="warning" pill @click="addQuestion(' ' + answerConfiguration.signature)">
          #assinatura</b-badge>&nbsp;
      </b-col>
    </b-row>
  </span>
</template>

<script>
import {
  BRow, BCol, BBadge,
} from 'bootstrap-vue'
import stringHelper from '@core-md/mixins/stringHelper'
import meliHelper from '@core-md/mixins/meliHelper'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
  },
  props: {
    question: {
      type: Object,
      default: null,
    },
    answerTemplates: {
      type: Array,
      default: null,
    },
    answerConfiguration: {
      type: Object,
      default: null,
    },
    viewDetail: {
      type: Boolean,
      default: false,
    },
    showDetail: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [
    stringHelper,
    meliHelper,
  ],
  computed: {
    autoGreeting() {
      return this.getGreetingByHour()
    },
  },
  methods: {
    addQuestion(content) {
      this.$emit('click', content)
    },
  },
}
</script>
