export default {
  fields: [
    {
      key: 'content',
      label: '',
      sortable: true,
      size: '220px',
      tdClass: 'pl-0 pl-sm-1',
      thClass: 'pl-0 pl-sm-1 text-left',
    },
    {
      key: 'tag',
      label: '',
      sortable: true,
      size: '80px',
      tdClass: 'pl-0 pl-sm-1',
      thClass: 'pl-0 pl-sm-1 text-left',
    },
    {
      key: 'actions',
      label: '',
      size: '140px',
      tdClass: 'pl-0 pl-sm-1 text-center text-sm-left',
      thClass: 'pl-0 pl-sm-1 text-left',
    },
  ],
}
