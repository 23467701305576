<template>
  <b-card no-body class="p-sm-0 pl-0 pr-0">
    <!-- form -->
    <b-form>
      <b-row>
        <b-col lg="6">
          <b-form-group label-for="general-greeting">
            <span slot="description">{{ $t('components.cards.meli.questions.settings.general_greeting_description') }}</span>
            <span slot="description" class="float-right">{{ answerConfiguration.greeting.length }}/50</span>
            <span slot="label"><b>{{ $t('components.cards.meli.questions.settings.general_greeting') }}</b></span>
            <span slot="label" class="float-right">
              <b-form-checkbox v-model="answerConfiguration.automatic_greeting" switch>
                <b>{{ $t('components.cards.meli.questions.settings.general_automatic') }}</b>
              </b-form-checkbox>
            </span>
            <b-input-group class="input-group-merge">
              <b-form-input id="general-greeting" v-model="answerConfiguration.greeting" :name="$t('components.cards.meli.questions.settings.general_greeting')"
                :placeholder="$t('components.cards.meli.questions.settings.general_greeting_placeholder')" maxlength="50" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-form-group label-for="general-signature">
            <span slot="description">{{ $t('components.cards.meli.questions.settings.general_signature_description') }}</span>
            <span slot="description" class="float-right">{{ answerConfiguration.signature.length }}/300</span>
            <span slot="label"><b>{{ $t('components.cards.meli.questions.settings.general_signature') }}</b></span>
            <span slot="label" class="float-right">
              <b-form-checkbox v-model="answerConfiguration.automatic_signature" switch>
                <b>{{ $t('components.cards.meli.questions.settings.general_automatic') }}</b>
              </b-form-checkbox>
            </span>
            <b-input-group class="input-group-merge">
              <b-form-input id="general-signature" v-model="answerConfiguration.signature" :name="$t('components.cards.meli.questions.settings.general_signature')"
                :placeholder="$t('components.cards.meli.questions.settings.general_signature_placeholder')" maxlength="300" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- buttons -->
        <b-col cols="12">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1" :disabled="formSubmitted" @click="preSubmit">
            <font-awesome-icon :icon="['fas', 'check']" v-if="!formSubmitted" class="mr-50" />
            <b-spinner v-if="formSubmitted" class="mr-50" :type="spinnerType" style="width: 16px; height: 16px;" />
            {{ $t('common.save') }}
          </b-button>
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" class="mt-1" @click="resetForm">
            <font-awesome-icon :icon="['fas', 'times']" class="mr-50" />
            {{ $t('common.reset') }}
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>

      <b-row>
        <b-col cols="12" class="mt-1">
          <span>{{ $t('components.cards.meli.questions.settings.general_answer_description') }}</span><br>
          <span class="text-primary"><span v-if="answerConfiguration.automatic_greeting">{{ answerConfiguration.greeting }} [{{ $t('components.cards.meli.questions.settings.general_from') }}].</span> [{{ $t('components.cards.meli.questions.settings.general_answer') }}].<span v-if="answerConfiguration.automatic_signature"> {{ answerConfiguration.signature }}</span></span>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BSpinner, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { $themeConfig } from '@themeConfig'
import Crypt from '@core-md/utils/crypt'
import handleAxiosException from '@core-md/mixins/errors'
import variants from '@core-md/mixins/variants'
import questionsService from '@/services/meli/questions/client'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BSpinner,
    BFormCheckbox,
  },
  props: {
    account: {
      type: Object,
      default: null,
    },
    inherit: {
      type: Boolean,
      default: true,
    },
  },
  directives: {
    Ripple,
  },
  mixins: [
    handleAxiosException,
    variants,
  ],
  data() {
    return {
      selectedAccount: null,
      answerConfiguration: {
        greeting: '',
        signature: '',
        automatic_greeting: false,
        automatic_signature: false,
      },
      formSubmitted: false,
      unWatchSelectedAccount: null,
    }
  },
  setup() {
    const { spinnerType } = $themeConfig.layout

    return {
      spinnerType,
    }
  },
  mounted() {
    if (!this.inherit || this.account) {
      this.selectedAccount = this.account
    } else {
      this.selectedAccount = this.$store.state.authorizations.selectedAccount
      this.unWatchSelectedAccount = this.$store.watch(() => this.$store.state.authorizations.selectedAccount, account => {
        if (account) {
          this.selectedAccount = account
          this.getAnswerConfiguration()
        }
      })
    }
    this.$store.dispatch('meli/initialize')
    this.getAnswerConfiguration()
  },
  beforeDestroy() {
    if (this.unWatchSelectedAccount) {
      this.unWatchSelectedAccount()
      this.unWatchSelectedAccount = null
    }
  },
  methods: {
    async getAnswerConfiguration() {
      this.resetForm()

      const request = {
        seller_id: this.selectedAccount.seller_id,
      }
      questionsService.getAnswerConfigurations(request).then(response => {
        if (response.status === 200) {
          const result = Crypt.decrypt(response.data.result)
          if (result) {
            this.answerConfiguration = result
          }
        }
      }).catch(data => {
        console.log(data)
      })
    },
    preSubmit() {
      this.submitForm()
    },
    submitForm() {
      this.formSubmitted = true
      const request = this.answerConfiguration
      questionsService.saveAnswerConfigurations(request).then(response => {
        if (response.status === 200) {
          const result = Crypt.decrypt(response.data.result)
          if (result.status
            && result.status === '0') {
            this.$root.showMessageSuccess(result.statusMessageDetail || result.statusMessage, this.$i18n.t('common.messages.success'))
          } else {
            this.$root.showMessageError(result.statusMessageDetail || result.statusMessage, this.$i18n.t('components.cards.meli.questions.settings.messages.general_error'))
          }
          this.formSubmitted = false
        }
      }).catch(error => {
        this.formSubmitted = false
        this.handleAxiosException(error, 'components.cards.meli.questions.settings.messages.general_error')
      })
    },
    resetForm() {
      this.answerConfiguration = {
        seller_id: this.selectedAccount.seller_id,
        greeting: '',
        signature: '',
        automatic_greeting: false,
        automatic_signature: false,
      }
    },
  },
}
</script>
