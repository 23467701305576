<template>
  <b-input-group class="input-group-merge">
    <v-select class="form-control" v-model="tag" multiple label="text" :placeholder="$t('common.type_something_to_search')"
      :options="options" @search="fetchOptions" @option:selected="executeSearch" @option:deselected="executeSearch">
      <template v-slot:no-options="{ search, searching }">
        <template v-if="searching">
          <span v-html="$t('common.no_results_for', {search: search})"></span>
        </template>
        <em v-else style="opacity: 0.5">{{ $t('common.type_something_to_search') }}</em>
      </template>
    </v-select>
    <b-input-group-append is-text v-if="false">
      <font-awesome-icon :icon="['fas', 'search']" class="text-primary" fixed-width @click="executeSearch" />
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import {
  BInputGroup, BInputGroupAppend,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import meliHelper from '@core-md/mixins/meliHelper'

export default {
  components: {
    BInputGroup,
    BInputGroupAppend,
    vSelect,
  },
  props: {
    filter: {
      type: Array,
      default: null,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [
    meliHelper,
  ],
  data() {
    return {
      tag: '',
      options: [],
      processing: false,
      questionStatus: [
        { id: 'unanswered', text: this.$i18n.t('components.cards.meli.questions.statuses.unanswered') },
        { id: 'answered', text: this.$i18n.t('components.cards.meli.questions.statuses.answered') },
        { id: 'banned', text: this.$i18n.t('components.cards.meli.questions.statuses.banned') },
        { id: 'deleted', text: this.$i18n.t('components.cards.meli.questions.statuses.deleted') },
        { id: 'disabled', text: this.$i18n.t('components.cards.meli.questions.statuses.disabled') },
        { id: 'under_review', text: this.$i18n.t('components.cards.meli.questions.statuses.under_review') },
        { id: 'close_unanswered', text: this.$i18n.t('components.cards.meli.questions.statuses.close_unanswered') },
      ],
    }
  },
  computed: {
    timezoneFormatComplete() {
      return 'yyyy-MM-ddTHH:mm:ss.000-03:00'
    },
  },
  methods: {
    fetchOptions(search) {
      if (search) {
        const autocompleteItems = []
        const patt = new RegExp(/^[0-9]*$/)
        const isId = patt.test(search)
        if (isId) {
          autocompleteItems.push({ option: 'id', value_id: search, text: this.$i18n.t('components.cards.meli.mp.collections.id') + ': ' + search }) // eslint-disable-line
          autocompleteItems.push({ option: 'payer.id', value_id: search, text: this.$i18n.t('components.cards.meli.mp.collections.payer_id') + ': ' + search }) // eslint-disable-line
        } else {
          // autocompleteItems.push({ option: 'payer.email', value_id: search, text: this.$i18n.t('components.cards.meli.mp.collections.payer') + ': ' + search }) // eslint-disable-line
        }

        if (search.length > 1) {
          const questionStatusItems = this.questionStatus.filter(i => i.text.toLowerCase().indexOf(search.toLowerCase()) !== -1)

          questionStatusItems.forEach(value => {
            autocompleteItems.push({ option: 'status', value_id: value.id, text: value.text })
          })
        }

        this.options = autocompleteItems
      }
    },
    clearSearch() {
      this.tag = []
      this.executeSearch()
    },
    executeSearch() {
      this.$emit('update:filter', this.tag)
      this.$emit('search')
    },
  },
  watch: {
    filter() {
      this.tag = this.filter
    },
  },
}
</script>

<style lang="css">
  .v-select {
    padding: 0px 0px !important;
  }

  .v-select .vs__dropdown-toggle {
    padding: 0px 0px;
    border: 0px;
  }

  .form-control {
    min-height: 2.714rem;
    height: unset !important;
  }

  .vs__deselect {
    fill: #fff !important;
  }
</style>
