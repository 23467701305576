<template>
  <b-button-toolbar>
    <b-button-group class="mr-1">
      <b-dropdown v-ripple.400="'rgba(255, 255, 255, 0.15)'" right no-flip variant="outline-primary" no-caret>
        <template slot="button-content">
          <font-awesome-icon :icon="['fas', 'filter']" fixed-width />
        </template>
        <b-row style="min-width:400px;">
          <b-col md="12">
            <b-dropdown-item @click.prevent="executeFilter()"><small class="text-danger"><strong>{{ $t('common.clear_filter') }}
                  <font-awesome-icon :icon="['fas', 'times']" fixed-width />
                </strong></small></b-dropdown-item>
            <b-dropdown-header><small class="text-primary"><strong>{{ $t('components.cards.meli.questions.status') }}</strong></small></b-dropdown-header>
            <b-dropdown-item v-for="status in statusList" :key="status" @click.prevent="executeFilter('status', status, $t('components.cards.meli.questions.statuses.' + status))"><small>{{ $t('components.cards.meli.questions.statuses.' + status) }}</small>
            </b-dropdown-item>
          </b-col>
        </b-row>
      </b-dropdown>
      <b-dropdown v-ripple.400="'rgba(255, 255, 255, 0.15)'" right no-flip variant="outline-primary" no-caret>
        <template slot="button-content">
          <font-awesome-icon :icon="['fas', 'sort-alpha-down']" fixed-width />
        </template>
        <b-dropdown-item v-for="sort in sortList" :key="sort + '_true'" @click.prevent="executeSort(sort, true)"><small>
            <font-awesome-icon :icon="['fas', 'check']" fixed-width class="text-primary"
              v-if="pagination.sortBy === sort && pagination.sortDesc" />
              <span v-if="sort === 'date_created'">
                {{ $t('common.sort_last_simple') }}
              </span>
              <span v-else>
                {{ $t('common.field_desc', {field: $t('components.cards.meli.questions.' + sort)}) }}
              </span>
            </small></b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item v-for="sort in sortList" :key="sort + '_false'" @click.prevent="executeSort(sort, false)"><small>
            <font-awesome-icon :icon="['fas', 'check']" fixed-width class="text-primary"
              v-if="pagination.sortBy === sort && !pagination.sortDesc" />
              <span v-if="sort === 'date_created'">
                {{ $t('common.sort_older_simple') }}
              </span>
              <span v-else>
                {{ $t('common.field_asc', {field: $t('components.cards.meli.questions.' + sort)}) }}
              </span>
          </small></b-dropdown-item>
      </b-dropdown>
    </b-button-group>
    <b-button-group>
      <b-button v-if="false" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" @click.prevent="downloadFile('xlsx')"
        v-b-tooltip.hover.v-primary :title="$t('common.tooltips.download')">
        <font-awesome-icon :icon="['fas', 'download']" fixed-width />
      </b-button>
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary"
        @click.prevent="toggleDetail"
        v-b-tooltip.hover.v-primary :title="$t('common.tooltips.show_hide_details')">
        <font-awesome-icon :icon="['fas', internalOptions.showDetail?'eye-slash':'eye']" fixed-width />
      </b-button>
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary"
        @click.prevent="toggleStacked"
        v-b-tooltip.hover.v-primary :title="$t('common.tooltips.toggle_view')">
        <font-awesome-icon :icon="['fas', internalOptions.tableStacked?'grip-horizontal':'grip-vertical']"
          fixed-width />
      </b-button>
    </b-button-group>
  </b-button-toolbar>
</template>

<script>
import {
  BRow, BCol, BButton, BButtonToolbar, BButtonGroup, BDropdown, BDropdownItem, BDropdownDivider, BDropdownHeader,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BButtonToolbar,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BDropdownHeader,
  },
  props: {
    options: {
      type: Object,
      default: null,
    },
    pagination: {
      type: Object,
      default: null,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      internalOptions: {
        showDetail: false,
        tableStacked: false,
      },
      statusList: [
        'unanswered',
        'answered',
        'banned',
        'deleted',
        'disabled',
        'under_review',
        'close_unanswered',
      ],
      sortList: [
        'date_created',
        'item_id',
        'from_id',
      ],
    }
  },
  methods: {
    executeFilter(field, value, text) {
      this.$emit('executeFilter', field, value, text)
    },
    executeSort(field, sortDesc) {
      this.$emit('executeSort', field, sortDesc)
    },
    toggleDetail() {
      this.internalOptions.showDetail = !this.internalOptions.showDetail
      this.$emit('update:options', this.internalOptions)
    },
    toggleStacked() {
      this.internalOptions.tableStacked = !this.internalOptions.tableStacked
      this.$emit('update:options', this.internalOptions)
    },
    downloadFile(format) {
      this.$emit('downloadFile', format)
    },
  },
  watch: {
    options() {
      this.internalOptions = this.options
    },
  },
}
</script>
