<template>
  <b-card no-body>
    <HTMLTitle :title="$t('navigation.menu.meli.questions')"></HTMLTitle>

    <card-header :title="$t('components.cards.meli.questions.page_title')" :icon="['far', 'question-circle']"
      :id="'meliQuestions'"
      :subTitle="(selectedAccount && selectedAccount.nickname) || $t('components.cards.common.select_account')"
      :description="$t('components.cards.meli.questions.page_info')">
    <span slot="optionIcons">
      <b-link @click="options.showInfo = !options.showInfo" v-b-tooltip.hover.v-primary
        :title="$t('common.tooltips.settings')">
        <font-awesome-icon v-if="!showSettings" :icon="['fas', 'cog']" size="2x" transform="shrink-5"
          class="text-primary" fixed-width @click="showSettings = !showSettings"/>
        <font-awesome-icon v-if="showSettings" :icon="['fas', 'times']" size="2x" transform="shrink-5" class="text-danger"
          fixed-width @click="showSettings = !showSettings" />
      </b-link>
    </span>
    </card-header>

    <card-questions v-if="!showSettings" :options="options" />
    <card-settings v-if="showSettings" />
  </b-card>
</template>

<script>
import {
  BCard, VBTooltip, BLink,
} from 'bootstrap-vue'
import HTMLTitle from '@core-md/layouts/components/HTMLTitle.vue'
import CardHeader from '@core-md/components/cards/CardHeader.vue'
import CardQuestions from '@/layouts/components/cards/meli/questions/CardQuestions.vue'
import CardSettings from '@/layouts/components/cards/meli/questions/settings/Settings.vue'

export default {
  components: {
    BCard,
    BLink,
    HTMLTitle,
    CardHeader,
    CardQuestions,
    CardSettings,
  },
  props: {
    account: {
      type: Object,
      default: null,
    },
    inherit: {
      type: Boolean,
      default: true,
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      options: {
        showTitle: false,
        showAccount: false,
        showInfo: false,
      },
      showSettings: false,
      selectedAccount: null,
      unWatchSelectedAccount: null,
    }
  },
  mounted() {
    if (!this.inherit || this.account) {
      this.selectedAccount = this.account
    } else {
      this.selectedAccount = this.$store.state.authorizations.selectedAccount
      this.unWatchSelectedAccount = this.$store.watch(() => this.$store.state.authorizations.selectedAccount, account => {
        if (account) {
          this.selectedAccount = account
        }
      })
    }
  },
  beforeDestroy() {
    if (this.unWatchSelectedAccount) {
      this.unWatchSelectedAccount()
      this.unWatchSelectedAccount = null
    }
  },
}
</script>
