<template>
  <b-card no-body>
    <HTMLTitle :title="$t('components.cards.meli.questions.settings.page_title')"></HTMLTitle>

    <card-header :title="$t('components.cards.meli.questions.settings.page_title')" :icon="['fas', 'cog']"></card-header>

    <b-tabs content-class="mt-1 mt-md-0 p-0" class="pt-1 pl-1 pr-1 pl-sm-1 pr-sm-1" justified>

      <!-- general tab -->
      <b-tab active>
        <!-- title -->
        <template #title>
          <font-awesome-icon :icon="['fas', 'comments']" class="m-0" fixed-width />
          <span class="font-weight-bold d-none d-md-inline ml-50">{{ $t('components.cards.meli.questions.settings.general') }}</span>
        </template>
        <general />
      </b-tab>
      <!--/ general tab -->

      <!-- quick answers tab -->
      <b-tab>
        <!-- title -->
        <template #title>
          <font-awesome-icon :icon="['fas', 'bolt']" class="m-0" fixed-width />
          <span class="font-weight-bold d-none d-md-inline ml-50">{{ $t('components.cards.meli.questions.settings.answer_templates') }}</span>
        </template>
        <answer-templates :options="options" />
      </b-tab>
      <!--/ quick answers tab -->

      <!-- no service answer tab -->
      <b-tab v-if="false">
        <!-- title -->
        <template #title>
          <font-awesome-icon :icon="['far', 'clock']" class="m-0" fixed-width />
          <span class="font-weight-bold d-none d-md-inline ml-50">{{
            $t('components.cards.meli.questions.settings.no_service_answer') }}</span>
        </template>
        <answer-templates />
      </b-tab>
      <!--/ no service answer tab -->

    </b-tabs>
  </b-card>
</template>

<script>
import { BTabs, BTab, BCard } from 'bootstrap-vue'
import HTMLTitle from '@core-md/layouts/components/HTMLTitle.vue'
import CardHeader from '@core-md/components/cards/CardHeader.vue'
import General from './General.vue'
import AnswerTemplates from './AnswerTemplates.vue'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    HTMLTitle,
    CardHeader,
    General,
    AnswerTemplates,
  },
  props: {
    options: {
      type: Object,
      default: null,
    },
  },
}
</script>
